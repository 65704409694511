import React, { useEffect, useState } from 'react'
import useFetch from '../../../src/hooks/useFetch';

import { Dropdown } from 'primereact/dropdown';

const SelectOption = ({id,api,processLabel,value,field,disabled,onChange,criteria}) => {
    const [options,setOptions] = useState([]);
    const {http_get: getRequest, http_get_criteria: getRequestCriteria, data: getData} = useFetch(api);
    useEffect(
        () => {
            if (criteria === undefined){
                getRequest();
            }
            if (criteria && criteria.length > 0){
                getRequestCriteria(criteria)
            }
        }
    ,[])
    useEffect(
        () => {
            if (criteria && criteria.length > 0){
                getRequestCriteria(criteria)
            }
        }
    ,[criteria])
    useEffect(() => {
        if (getData){
            var result = [];
            getData.map(
                e => {
                    result.push({label: processLabel(e), value: e});
                }
            )
            setOptions(result);
        }
    },[getData])
    return (
        <>
           {value != undefined ? <Dropdown id={id} {...field} options={options} value={value} style={{width: "100%"}} disabled={disabled} onChange={onChange} />
           : <Dropdown id={id} {...field} options={options} style={{width: "100%"}} disabled={disabled} onChange={onChange} />}
        </>
    )
}

export default SelectOption