import { Fragment } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Logradouro from '../pages/Logradouro/Logradouro'

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route exact path="/logradouro/:appId/:usuarioId" element={<Logradouro/>} />
          <Route exact path="/logradouro/:appId/:usuarioId/:enderecoId" element={<Logradouro/>} />
        </Routes>
      </Fragment>
    </BrowserRouter>
  )
}

export default RoutesApp