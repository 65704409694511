import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import useFetch from '../../hooks/useFetch';

import { classNames } from 'primereact/utils';
import wpmanager from '../../assets/img/wp.png';

import style from './Logradouro.module.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import SelectOption from '../../components/SelectOption/SelectOption';

const Logradouro = () => {
    const {http_post: createRequest, data: dataPost} = useFetch("endereco-integracao/external");
    const {appId,usuarioId,enderecoId} = useParams();
    const [enderecoEspecial,setEnderecoEspecial] = useState(false);
    const [ufCriteria,setUfCriteria] = useState(false);
    const [localidadeCriteria,setLocalidadeCriteria] = useState(false);
    const [bairroCriteria,setBairroCriteria] = useState(false)
    const [tipoLogradouroCriteria,setTipoLogradouroCriteria] = useState(false);
    const [logradouroCriteria,setLogradouroCriteria] = useState(false);
    const { control, handleSubmit, reset, setValue, watch,formState: { errors, isValid } } = useForm(
        {
            mode: "onChange",
            defaultValues: {
                pais: null,
                uf: null,
                localidade: null,
                bairro: null,
                tipoLogradouro: null,
                logradouro: null,
                cep: '',
                numeroPorta: '',
                tipoComplemento1: null,
                numeroComplemento1: '',
                tipoComplemento2: null,
                numeroComplemento2: '',
                tipoComplemento3: null,
                numeroComplemento3: '',

                paisTexto: '',
                cepTexto: '',
                ufTexto: '',
                localidadeTexto: '',
                bairroTexto: '',
                tipoLogradouroTexto: '',
                nomeLogradouroTexto: '',
                tipoComplemento1Texto: '',
                tipoComplemento2Texto: '',
                tipoComplemento3Texto: ''
            }
        }
    );
    const onSubmit = (formData, e) => {
        formData.indicaResolvido = enderecoEspecial ? 'N' : 'S'
        createRequest({
            clienteAplicacao: appId,
            usuarioId: usuarioId,
            endereco: formData,
            atendimento: 'N'
        })
    };
    useEffect(
        () => {
            if (dataPost)
                window.close()
        }
    ,[dataPost])
    const onError = (errors, e) => console.log(errors, e);
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-invalid">{errors[name].message}</small>
    };
    const updateUfCriteria = (pais) => {
        var filters = [];
        filters.push(
            {
                columnName: 'sigla',
                searchType: 'equals',
                value: pais.sigla
            }
        )
        setUfCriteria(filters);
    }
    const updateTipoLogradouroCriteria = (pais) => {
        var filters = [];
        filters.push(
            {
                columnName: 'siglaPais',
                searchType: 'equals',
                value: pais.sigla
            }
        )
        setTipoLogradouroCriteria(filters);
    }
    const updateLocalidadeCriteria = (uf) => {
        var filters = [];
        filters.push(
            {
                columnName: 'ufId',
                searchType: 'equals',
                value: uf.id
            }
        )
        setLocalidadeCriteria(filters);
    }
    const updateBairroCriteria = (localidade) => {
        var filters = [];
        filters.push(
            {
                columnName: 'localidadeId',
                searchType: 'equals',
                value: localidade.id
            }
        )
        setBairroCriteria(filters);
    }
    const buscarLogradouro = () => {
        if (watch('bairro')?.id && watch('tipoLogradouro')?.id){
            var filters = [];
            filters.push(
                {
                    columnName: 'bairroId',
                    searchType: 'equals',
                    value: watch('bairro').id
                },
                {
                    columnName: 'tipoLogradouroId',
                    searchType: 'equals',
                    value: watch('tipoLogradouro').id
                }
            )
            setLogradouroCriteria(filters)
        }
    }
    
    return (
        <>
            <div className={style["container-bar"]}>
                <div className="logo">
                    <img src={wpmanager} style={{ top: "16px", left: "21px", position: "absolute", height: "30px" }} alt="wpmanager" />
                </div>
                <div className={style["container-center-center"]}>
                    <span className={style["title"]}>Logradouro</span>
                </div>
            </div>
            <div className={style.container}>
                <form className={classNames(style.alinhamento,style.card)} onSubmit={handleSubmit(onSubmit, onError)}>
                    { !enderecoEspecial && <>
                        <div className={classNames(style.field,style.field100)}>
                            <Button type="button" label="Novo Endereço" icon="pi pi-globe" onClick={() => {setEnderecoEspecial(true)}}/>
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="pais" className={classNames(style.fieldTitle,{ 'p-invalid': errors.pais })}>País</label>
                            <Controller name="pais" control={control} rules={{ }} render={({ field, fieldState }) => (
                                <SelectOption id={field.name} api="pais/external" processLabel={(e)=>{return e.sigla+' - '+e.nomePortugues}} 
                                    field={field} disabled={false} onChange={(e) => {updateUfCriteria(e.value);updateTipoLogradouroCriteria(e.value); setValue('pais', e.value)}}/>
                            )} />
                            {getFormErrorMessage('pais')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="cep" className={classNames(style.fieldTitle,{ 'p-invalid': errors.cep })} >CEP</label>
                            <Controller name="cep" control={control} rules={{ maxLength: 8}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="8" disabled={false}/>
                            )} />
                            {getFormErrorMessage('cep')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="uf" className={classNames(style.fieldTitle,{ 'p-invalid': errors.uf })}>UF</label>
                            <Controller name="uf" control={control} rules={{ }} render={({ field, fieldState }) => (
                                <SelectOption id={field.name} api="uf/external" processLabel={(e)=>{return e.codigo+' - '+e.nome}} 
                                    field={field} disabled={false} onChange={(e) => {updateLocalidadeCriteria(e.value);  setValue('uf', e.value)}} criteria={ufCriteria}/>
                            )} />
                            {getFormErrorMessage('uf')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="localidade" className={classNames(style.fieldTitle,{ 'p-invalid': errors.localidade })}>Localidade</label>
                            <Controller name="localidade" control={control} rules={{ }} render={({ field, fieldState }) => (
                                <SelectOption id={field.name} api="localidade/external" processLabel={(e)=>{return e.codigoAlpha+' - '+e.nome}} 
                                    field={field} disabled={false} onChange={(e) => {updateBairroCriteria(e.value); setValue('localidade', e.value)}} criteria={localidadeCriteria}/>
                            )} />
                            {getFormErrorMessage('localidade')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="bairro" className={classNames(style.fieldTitle,{ 'p-invalid': errors.bairro })}>Bairro</label>
                            <Controller name="bairro" control={control} rules={{ }} render={({ field, fieldState }) => (
                                <SelectOption id={field.name} api="bairro/external" processLabel={(e)=>{return e.nome}} 
                                    field={field} disabled={false} onChange={(e) => {buscarLogradouro(); setValue('bairro', e.value)}} criteria={bairroCriteria}/>
                            )} /> 
                            {getFormErrorMessage('bairro')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="tipoLogradouro" className={classNames(style.fieldTitle,{ 'p-invalid': errors.tipoLogradouro })}>Tipo Logradouro</label>
                            <Controller name="tipoLogradouro" control={control} rules={{ }} render={({ field, fieldState }) => (
                                <SelectOption id={field.name} api="tipo-logradouro/external" processLabel={(e)=>{return e.codigo+' - '+e.descricao}} 
                                    field={field} disabled={false} onChange={(e) => {buscarLogradouro(); setValue('tipoLogradouro', e.value)}} criteria={tipoLogradouroCriteria}/>
                            )} />
                            {getFormErrorMessage('tipoLogradouro')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="logradouro" className={classNames(style.fieldTitle,{ 'p-invalid': errors.logradouro })}>Logradouro</label>
                            <Controller name="logradouro" control={control} rules={{ }} render={({ field, fieldState }) => (
                                <SelectOption id={field.name} api="logradouro-bairro/external" processLabel={(e)=>{return e.logradouro.nome}} 
                                    field={field} disabled={false} onChange={(e) => {setValue('logradouro', e.value)}} criteria={logradouroCriteria}/>
                            )} />
                            {getFormErrorMessage('logradouro')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="numeroPorta" className={classNames(style.fieldTitle,{ 'p-invalid': errors.numeroPorta })} >Número Porta</label>
                            <Controller name="numeroPorta" control={control} rules={{ maxLength: 5}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="5" disabled={false}/>
                            )} />
                            {getFormErrorMessage('numeroPorta')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="tipoComplemento1" className={classNames(style.fieldTitle,{ 'p-invalid': errors.tipoComplemento1 })}>Tipo Complemento 1</label>
                            <Controller name="tipoComplemento1" control={control} rules={{ }} render={({ field, fieldState }) => (
                                <SelectOption id={field.name} api="tipo-complemento/external" processLabel={(e)=>{return e.codigo+ ' - '+e.descricao}} 
                                    field={field} disabled={false} onChange={(e) => {setValue('tipoComplemento1', e.value)}}/>
                            )} />
                            {getFormErrorMessage('tipoComplemento1')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="numeroComplemento1" className={classNames(style.fieldTitle,{ 'p-invalid': errors.numeroComplemento1 })} >Complemento 1</label>
                            <Controller name="numeroComplemento1" control={control} rules={{ maxLength: 5}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="5" disabled={false}/>
                            )} />
                            {getFormErrorMessage('numeroComplemento1')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="tipoComplemento2" className={classNames(style.fieldTitle,{ 'p-invalid': errors.tipoComplemento2 })}>Tipo Complemento 2</label>
                            <Controller name="tipoComplemento2" control={control} rules={{ }} render={({ field, fieldState }) => (
                                <SelectOption id={field.name} api="tipo-complemento/external" processLabel={(e)=>{return e.codigo+ ' - '+e.descricao}} 
                                    field={field} disabled={false} onChange={(e) => {setValue('tipoComplemento2', e.value)}}/>
                            )} />
                            {getFormErrorMessage('tipoComplemento2')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="numeroComplemento2" className={classNames(style.fieldTitle,{ 'p-invalid': errors.numeroComplemento2 })} >Complemento 2</label>
                            <Controller name="numeroComplemento2" control={control} rules={{ maxLength: 5}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="5" disabled={false}/>
                            )} />
                            {getFormErrorMessage('numeroComplemento2')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="tipoComplemento3" className={classNames(style.fieldTitle,{ 'p-invalid': errors.tipoComplemento3 })}>Tipo Complemento 3</label>
                            <Controller name="tipoComplemento3" control={control} rules={{ }} render={({ field, fieldState }) => (
                                <SelectOption id={field.name} api="tipo-complemento/external" processLabel={(e)=>{return e.codigo+ ' - '+e.descricao}} 
                                    field={field} disabled={false} onChange={(e) => {setValue('tipoComplemento3', e.value)}}/>
                            )} />
                            {getFormErrorMessage('tipoComplemento3')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="numeroComplemento3" className={classNames(style.fieldTitle,{ 'p-invalid': errors.numeroComplemento3 })} >Complemento 3</label>
                            <Controller name="numeroComplemento3" control={control} rules={{ maxLength: 5}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="5" disabled={false}/>
                            )} />
                            {getFormErrorMessage('numeroComplemento3')}
                        </div>
                    </>}
                    { enderecoEspecial && <>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="paisTexto" className={classNames(style.fieldTitle,{ 'p-invalid': errors.paisTexto })} >País</label>
                            <Controller name="paisTexto" control={control} rules={{ maxLength: 30}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="30" disabled={false}/>
                            )} />
                            {getFormErrorMessage('paisTexto')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="cepTexto" className={classNames(style.fieldTitle,{ 'p-invalid': errors.cepTexto })} >CEP</label>
                            <Controller name="cepTexto" control={control} rules={{ maxLength: 30}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="30" disabled={false}/>
                            )} />
                            {getFormErrorMessage('cepTexto')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="ufTexto" className={classNames(style.fieldTitle,{ 'p-invalid': errors.ufTexto })} >UF</label>
                            <Controller name="ufTexto" control={control} rules={{ maxLength: 30}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="30" disabled={false}/>
                            )} />
                            {getFormErrorMessage('ufTexto')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="localidadeTexto" className={classNames(style.fieldTitle,{ 'p-invalid': errors.localidadeTexto })} >Localidade</label>
                            <Controller name="localidadeTexto" control={control} rules={{ maxLength: 30}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="30" disabled={false}/>
                            )} />
                            {getFormErrorMessage('localidadeTexto')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="bairroTexto" className={classNames(style.fieldTitle,{ 'p-invalid': errors.bairroTexto })} >Bairro</label>
                            <Controller name="bairroTexto" control={control} rules={{ maxLength: 30}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="30" disabled={false}/>
                            )} />
                            {getFormErrorMessage('bairroTexto')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="tipoLogradouroTexto" className={classNames(style.fieldTitle,{ 'p-invalid': errors.tipoLogradouroTexto })} >Tipo Logradouro</label>
                            <Controller name="tipoLogradouroTexto" control={control} rules={{ maxLength: 30}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="30" disabled={false}/>
                            )} />
                            {getFormErrorMessage('tipoLogradouroTexto')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="nomeLogradouroTexto" className={classNames(style.fieldTitle,{ 'p-invalid': errors.nomeLogradouroTexto })} >Logradouro</label>
                            <Controller name="nomeLogradouroTexto" control={control} rules={{ maxLength: 30}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="30" disabled={false}/>
                            )} />
                            {getFormErrorMessage('nomeLogradouroTexto')}
                        </div>
                        <div className={classNames(style.field,style.field49)}>
                            <label htmlFor="numeroPorta" className={classNames(style.fieldTitle,{ 'p-invalid': errors.numeroPorta })} >Número Porta</label>
                            <Controller name="numeroPorta" control={control} rules={{ maxLength: 5}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="5" disabled={false}/>
                            )} />
                            {getFormErrorMessage('numeroPorta')}
                        </div>
                        <div className={classNames(style.field,style.field100)}>
                            <label htmlFor="tipoComplemento1Texto" className={classNames(style.fieldTitle,{ 'p-invalid': errors.tipoComplemento1Texto })} >Complemento 1</label>
                            <Controller name="tipoComplemento1Texto" control={control} rules={{ maxLength: 30}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="30" disabled={false}/>
                            )} />
                            {getFormErrorMessage('tipoComplemento1Texto')}
                        </div>
                        <div className={classNames(style.field,style.field100)}>
                            <label htmlFor="tipoComplemento2Texto" className={classNames(style.fieldTitle,{ 'p-invalid': errors.tipoComplemento2Texto })} >Complemento 2</label>
                            <Controller name="tipoComplemento2Texto" control={control} rules={{ maxLength: 30}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="30" disabled={false}/>
                            )} />
                            {getFormErrorMessage('tipoComplemento2Texto')}
                        </div>
                        <div className={classNames(style.field,style.field100)}>
                            <label htmlFor="tipoComplemento3Texto" className={classNames(style.fieldTitle,{ 'p-invalid': errors.tipoComplemento3Texto })} >Complemento 3</label>
                            <Controller name="tipoComplemento3Texto" control={control} rules={{ maxLength: 30}} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} style={{width: "100%"}} maxLength="30" disabled={false}/>
                            )} />
                            {getFormErrorMessage('tipoComplemento3Texto')}
                        </div>
                    </>}
                    <div className={classNames(style['container-end'])} >
                        <Button type="submit" label="Salvar" icon="pi pi-check" />
                    </div>
                </form>
            </div>
            
        </>
    ) 
}

export default Logradouro